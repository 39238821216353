import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Coplas - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Coplas"
        canonical="https://eduardoceballos.com/poemas/coplas"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/coplas",
          title: "Coplas - Poemas",
          description: "Coplas",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Coplas</h1>
      <center>
        <p>
          En el color de la uva,
          <br />
          vive una niña tierna
          <br />
          que juega en sus acequias
          <br />
          la ronda de luna llena.
          <br />
          &nbsp;
          <br />
          En el canto de los pueblos
          <br />
          Hay un rumor de tiempos
          <br />
          que se descuelga despacio
          <br />
          para llenar nuestro suelo.
          <br />
          &nbsp;
          <br />
          Un sueño de cielo largo
          <br />
          de sabor cafayateño
          <br />
          ya me espera en el vino
          <br />
          con un fruto guitarrero.
          <br />
          &nbsp;
          <br />
          Con carnaval y con cajas
          <br />
          sale el diablo a cantar
          <br />y se queda en Cafayate
          <br />
          para el tiempo de chayar.
          <br />
          &nbsp;
          <br />
          En la parra de los valles
          <br />
          hay un algo que le anda
          <br />
          alimenta su fuerte tallo
          <br />y tiene el color del agua.
          <br />
          &nbsp;
          <br />
          Alegrate Cafayate
          <br />
          corazón del calchaquí,
          <br />
          dejame que yo te cante
          <br />
          con mis paisanos aquí.
          <br />
          &nbsp;
          <br />
          Cafayateño de piel oscura
          <br />
          sabedor de sol y arena
          <br />
          yo te descubro en las uvas
          <br />
          el corazón serenatero.
          <br />
          &nbsp;
          <br />
          La coca, el pan verde,
          <br />
          No se mastica, compañero.
          <br />
          Hay que dejarla se quede
          <br />a que borre nuestro sueño
          <br />
          para poder vivir despiertos
          <br />
          estos tiempos cancioneros.
          <br />
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
